a {
  color: #282c34;
  text-decoration: none;
}

img {
  max-width: 45vw;
  height: auto;
  object-fit: cover;
  object-position: top center;
  margin-top: 3vh;
}

ul {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.App {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  margin: 0 10% 10%;
}

.App-details {
  font-size: 24px;
  margin: 0 20% 0;
}

.App-details-header, .Event-details-name {
  font-size: 30px;
}

.App-details-title {
  font-size: 36px;
}

.App-section-title {
  font-size: 48px;
}

.App-header {
  min-height: 25vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header h1 {
  font-size: 72px;
}

.App-table-container, .Contact-form, .RSVP-form, .Auth-form {
  width: 50vw;
}

.App-tr {
  width: calc(100% / 2);
  text-align: center;
}

.Auth-toggle {
  display: flex;
  padding: 0.5em 10vw;
}

.Auth-toggle-label {
  align-self: center;
}

.Banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background-color: #bbb;
}

.Banner p {
  margin: 0;
  padding-left: .5em;
}

.Banner-close {
  color: gray;
  font-size: 20px;
  font-weight: bold;
  padding-right: .5em;
}

.Border-solid {
  border-top: 3px solid #bbb;
  border-radius: 5px;
}

.caption {
  font-size: small;
  margin: 0 0 5vh;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 1vh;
  border: none;
  text-align: center;
  width: 100%;
  align-self: center;
}

.collapsible.active, .collapsible:hover {
  background-color: #555;
}

.content {
  display: none;
  overflow: hidden;
  /* background-color: #f1f1f1; */
}

.Contact-fields, .RSVP-fields, .Auth-fields {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0.5em 10vw;
}

.Contact-input, .RSVP-input, .Auth-input {
  text-align: left;
  width: 95%;
  height: 1.5em;
  align-self: flex-start;
  font-family: 'Kurale', serif;
  font-size: large;
}

.Contact-fields textarea {
  height: 5em;
}

.Contact-label, .RSVP-label, .Auth-label {
  align-self: flex-start;
}

.Contact-submit, .RSVP-submit, .Auth-submit {
  font-family: 'Kurale', serif;
  font-size: large;
}

.Divider {
  height: 100px;
  margin: 5% 0 5%;
}

.Event-details {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.Event-details-description {
  padding-left: 2vw;
}

.Event-details-time, .subtitle {
  font-size: 20px;
  color: gray;
  margin: 0;
}

.first {
  padding-right: 2vw;
}

.header {
  display: flex;
  position: absolute;
  justify-content: end;
  font-size: 20px;
  margin-right: 10px;
  right: 0%;
}

.link {
  color: #282c34;
  cursor: pointer;
}

.link-container {
  margin: 1vh 0;
}

.Modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.Modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.Modal-close:hover, .Modal-close:focus, .Banner-close:hover, .Banner-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.Modal-content {
  background-color: #fff8f7;
  margin: auto;
  padding: 1%;
  border: 1px solid #888;
  border-radius: 25px;
  max-width: 50%;
  max-height: 75vh;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.Modal-content::-webkit-scrollbar {
  width: 8px;
}

.Modal-content::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 10px;
}

.Modal-content::-webkit-scrollbar-track {
  background: transparent;
}

.Modal-slider {
  text-align: center;
  overflow: hidden;
}

.home-button {
  font-family: 'Kurale', serif;
  font-size: large;
  cursor: pointer;
  margin: 1px;
}

.Modal-slider > button {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.Modal-slider > .modal-active, button:hover {
  background: #339AF0;
}

.Nav-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 25px;
  margin-bottom: 5%;
}

.Nav-content a.active, a:hover, .link:hover {
  color: #339AF0;
}

.Nav-menu {
  display: none;
  color: #282c34;
}

.Nav p {
  padding: 0px 1vw 0px;
}

.Nav-top {
  position: relative;
  left: 90%;
  cursor: pointer;
}

.Nav-top img {
  max-width: 50px;
  height: auto;
}

.Party-details {
  display: flex;
  align-items: start;
}

.Profile-details {
  margin-bottom: 2em;
}

.Profile-details img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index:999;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.Profile-details img:hover {
  transform: scale(1.1);
}

.Profile-details p {
  margin: 0;
}

.Profile-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Profile-modal img {
  max-width: 50%;
  height: 10em;
  border-radius: 50%;
  padding: 4%;
}

.Profile-modal-details {
  margin: 0 1em;
  text-align: left;
}

.Question-details {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.RSVP-fields textarea {
  height: 5em;
  align-self: center;
}

.RSVP-groups {
  display: flex;
  flex-flow: column;
  margin: 1em 0;
}

.RSVP-options {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 10vw;
}

.RSVP-options input {
  margin-right: 2vw;
}

.RSVP-submit, .Auth-submit {
  font-family: 'Kurale', serif;
  font-size: large;
  margin: 2vw;
}

.RSVP-text {
  width: calc(90% / 2);
}

.RSVP-text input.RSVP-input {
  padding: 0;
  margin: 0;
  width: calc(150% / 2);
}

.RSVP-textfields {
  display: flex;
  justify-content: space-between;
}

.section {
  display: flex;
  flex-flow: column;
}

.Travel-info {
  margin: 1.5em 0;
}

.underline {
  text-decoration: underline;
}

.validation {
  border-color: #339AF0;
  box-shadow: 0 0 5px #339AF0;
}

/* Screen threshold where the Navbar goes to next row */
@media all and (max-width: 1230px) {
  img {
    max-width: 100%;
    height: 500px;
    object-position: center;
  }

  /* For first engagement photo */
  img.img-left {
    object-position: 25%;
  }

  .App-details {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 15% 0;
  }

  .App-header {
    margin-top: 10%;
  }

  .App-tr {
    width: 100%;
  }

  .Auth-toggle {
    margin-bottom: 5vh; 
  }

  .Contact-fields, .RSVP-fields, .Auth-fields {
    padding: 0.5em 0;
  }

  .Border-solid {
    border-top: 3px solid #bbb;
    border-left: 75vw solid #bbb;
  }

  .Event-details, .Question-details {
    flex-flow: wrap;
  }

  .Event-details-description {
    padding-left: 0;
  }

  .first {
    padding-right: 0;
  }

  .Nav {
    display: flex;
    flex-direction: column;
    /* position: relative; */
    /* align-self: flex-start; */
    /* margin-left: -10px; */
    order: 1;
  }

  .Nav-content {
    display: none;
  }

  .Nav-menu {
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    /* padding: 10px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
    align-items: center;
    border-radius: 25px;
    font-family: Kurale;
  }

  .Nav-menu img {
    max-width: 50px;
    height: auto;
    margin: 0;
  }

  .Nav p {
    padding: 0;
    margin: 1%;
    text-wrap: wrap;
  }

  .Nav:hover .Nav-content {
    display: flex;
    flex-flow: column;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 0;
  }

  .Nav-top img {
    max-width: 10vw;
    height: auto;
  }

  .Party-details {
    flex-flow: wrap;
  }

  .Profile-modal {
    flex-flow: wrap-reverse;
  }

  .Profile-modal img {
    height: auto;
  }

  .RSVP-form {
    width: 80vw;
  }

  .RSVP-options {
    flex-flow: column;
  }

  .title {
    order: 2;
  }

  .title-hidden {
    display: none;
  }
}

@media all and (max-width: 600px) {
  .Modal-content {
    max-width: 100%;
  }

  #message { 
    margin-top: 0;
  }

  #message img {
    display: none;
  }
}

@media all and (max-width: 319px) {
  img {
    display: none;
  }
}

/* 404 Error Page */
#message { 
  width: 40vw;
  margin: 10vh auto;
  display: flex;
  flex-flow: column;
  align-items: start;
}

#message h3 { 
  color: #888;
  font-weight: normal;
  font-size: 16px;
}

#message h2 { 
  color: #ffa100;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 8px;
}

#message h1 { 
  font-size: 22px;
  font-weight: 300;
  color: rgba(0,0,0,0.6);
  margin: 0 0 16px;
}

#message p { 
  line-height: 140%;
  font-size: 14px;
}

#message img { 
  width: 100%;
  margin: 0;
}

/* .App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
