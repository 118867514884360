body {
  background-color: #fff8f7;
  background-image: url("images/transparent-cherry-blossom-pink-and-white-cherry-blossoms-on-branch.png"), url("images/transparent-watercolor-painting-of-pink-cherry-blossom.png");
  background-position: top left, top right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 20vw;
  color: #282c34;
  margin: 0;
  font-family: 'Kurale', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  text-align: center;
  font-size: small;
}

@font-face {
  font-family: 'Kurale';
  font-style: normal;
  font-weight: normal;
  src: local('Kurale'), url('./fonts/Kurale-Regular.ttf') format('truetype'), url('./fonts/Kurale-Regular.eot') format('embedded-opentype'), url('./fonts/Kurale-Regular.woff') format('woff'), url('./fonts/Kurale-Regular.woff2') format('woff2'), url('./fonts/Kurale-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Great Vibes';
  src: local('Great Vibes'), url('./fonts/GreatVibes-Regular.ttf') format('truetype');
}

@media all and (max-width: 700px) {
  body {
    background-image: none;
  }
}

@media all and (max-width: 170px) {
  #root {
    display: none;
  }
}

/* body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
